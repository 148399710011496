import { useEffect } from "react"
import ReactQuill, { Quill }  from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import Quill from 'quill'
// import "quill/dist/quill.snow.css"
// import { io } from "socket.io-client"
// import { useParams } from "react-router-dom"
import "./TextEditorQuill.css";
// import {isMobile} from 'react-device-detect';
import { TextEditorQuill_Scripts } from "./TextEditorQuill_Scripts";
import debounce from '../helpers';


//-- Quill toolbar modules & history settings
  const  modules  = {
    toolbar: {
      container: [
        ["undo", "redo"],
        [{ header: [1, 2, 3, false] }],
        // [{ "header" :1}, { "header" :2}, { "header" :3}, { "header" :0}],
          ///[{ font: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: ["", "red", "green", "gray"] }, { background: ["yellow", "cyan", "pink", "green", "gray", "black"] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }, { list: "check" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        "undo": () => qlUndo(),
        "redo": () => qlRedo(),
      },
    },
    // preserveWhiteSpace: true,
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
 
    clipboard: {
      //-- To avoid new line when reloading || https://stackoverflow.com/questions/63678128/how-to-prevent-react-quill-to-insert-a-new-line-before-list-when-re-loading-cont
      matchVisual: false
    },
  }

//-- Quill undo/redo functions for handler buttons in toolbar || https://stackoverflow.com/questions/59555447/how-to-create-undo-redo-buttons-in-quill-js-react-quill
  //-- Undo/redo icons
  var icons = Quill.import("ui/icons");
      icons["undo"] = `<svg viewbox="0 0 18 18">
      <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
      <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
    </svg>`;
      icons["redo"] = `<svg viewbox="0 0 18 18">
      <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
      <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
    </svg>`;
  // Undo function
  var reactQuillRef = null;
  function qlUndo() {
    // console.log('undo');
    let qlEditor = reactQuillRef.getEditor();
    // console.log(qlEditor.history);
    return qlEditor.history.undo();
  }
  //-- Redo function
  function qlRedo() {
    // console.log('redo');
    let qlEditor = reactQuillRef.getEditor();
    // console.log(qlEditor.history);
    return qlEditor.history.redo();
  }

export default function TextEditorQuill({noteId, editorContent, onEditField}) {
  const { qlToolbarPosition, qlViewResize, qlToolbarSwitch} = TextEditorQuill_Scripts();

  //-- Quill toolbar positioning mobile/desktop
    useEffect(()=>{
      // setTimeout(() => {
        qlToolbarPosition();
        qlViewResize();
      // }, 0);
    }, []) // <-- empty dependency array    

    // const onEditFieldd = (field, value) => {
    //   // if (editorContent) { onEditField(field, value) }
    //   // console.log("dd"+editorContent)
    //   // onEditField(field, value);
    //   update(field, value);
    // }

    const updateBody = debounce((field, value) => {
      console.log('UPD DB');
      onEditField(field, value);
    }, 1000)

  return(
      <ReactQuill 
        value={editorContent} 
        modules={modules}
        onChange= {(e) => updateBody("body", e)} //-- For updating DB
        ref={(e) => reactQuillRef = e} //-- For undo/redo handlers
        onFocus={(e) => qlToolbarSwitch(true)} //-- Switch toolbar on/off
        // onBlur={(e) => qlToolbarSwitch(false)}
        preserveWhitespace
      />
  )
}
