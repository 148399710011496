import React, { useEffect, useState, useRef } from 'react'
import uuid from "react-uuid";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, onSnapshot, getDoc, setDoc } from "firebase/firestore"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import { Card, Button, Alert } from "react-bootstrap"
import './Home.css'
import { db } from "../firebase/firebase-config"
import Main from "../main/Main";
import Sidebar from "../sidebar/Sidebar";
import debounce from '../helpers';



export default function Home() {

//-- Notes stuff

  // const [notes, setNotes] = useState([]);
  // const notesCollectionRef = collection(db, "notes");

  // const [newTitle, setNewTitle] = useState("");
  // const [newBody, setNewBody] = useState("");

  // useEffect(() => {
  //   const getNotes = async () => {
  //     const data = await getDocs(notesCollectionRef)
  //     console.log(data);
  //     setNotes (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   }
  //   getNotes();
  // }, [])

  // const createNote = async () => {
  //   await addDoc(notesCollectionRef, { title: newTitle, body: newBody });
  // };

  // const updateNote = async (id, body) => {
  //   const noteDoc = doc(db, "notes", id);
  //   const updFields = { body: body + " a" };
  //   await updateDoc(noteDoc, updFields);
  // };

  // const deleteNote = async (id) => {
  //   const noteDoc = doc(db, "notes", id);
  //   await deleteDoc(noteDoc);
  // };


//-- Auth stuff

  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()

  async function handleLogout() {
    setError("")
    try {
      await logout()
      navigate.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

//-- Notes app stuff

  const notesCollectionRef = collection(db, "notes");

  const [notes, setNotes] = useState(
    // localStorage.notes ? JSON.parse(localStorage.notes) : []
    []
    // getNotes
  );

  // const getNotes = async () => {
  //   const data = await getDocs(notesCollectionRef)
  //   console.log(data);
  //   // setNotes (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   return (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  // }

  useEffect(() => {

    // //-- Get notes from Firebase once
    // const getNotes = async () => {
    //   const data = await getDocs(notesCollectionRef)
    //   // console.log('aaa');
    //   console.log(data);
    //   setNotes (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    // }
    // getNotes();
    // // console.log('aa');

    //-- Get notes from Firebase in real time
    //-- https://firebase.google.com/docs/firestore/query-data/listen
    //-- https://stackoverflow.com/questions/59944658/which-react-hook-to-use-with-firestore-onsnapshot
    const unsubscribe = onSnapshot(notesCollectionRef, (data) => {
      // console.log('DATA: ' + data);
      setNotes (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    //remember to unsubscribe from your realtime listener on unmount or youwill create a memory leak
    return () => unsubscribe();

    //-- Timer
    //-- https://stackoverflow.com/questions/39426083/update-react-component-every-second
    const interval = setInterval(() => timerUpdate(), 4000);
    return () => {
      clearInterval(interval);
    };

  }, [])

  const [activeNote, setActiveNote] = useState(false);

  // useEffect(() => {
  //   // localStorage.setItem("notes", JSON.stringify(notes));
  //   // console.log('aa' + JSON.parse(localStorage.notes));
  //   activeNote && console.log('activeNote: ' +  getActiveNote().id + ' - ' + getActiveNote().body);
  //   activeNote && updateNote(
  //     getActiveNote().id,
  //     getActiveNote().title,
  //     getActiveNote().body
  //   )
  // }, [notes]);

  var currNoteId = useRef();
  useEffect(() => {
    // action on update of movies
    currNoteId.current = activeNote;
    // console.log('currNoteId.current: ' + activeNote);
  }, [activeNote]);

  var currNotes = useRef();
  useEffect(() => {
    // action on update of movies
    currNotes.current = notes;
    // console.log('currNotes.current: ' + notes.length);
  }, [notes]);

  
  const updateNote = async (updatedNote) => {
    console.log('UPD NOTE');
    console.log(updatedNote);
    const noteDoc = doc(db, "notes", updatedNote.id);
    const updFields = { 
      ...(updatedNote.nest || updatedNote.nest === '')  && {nest: updatedNote.nest},
      // nest: updatedNote.nest,
      ...(updatedNote.pid || updatedNote.pid === '')  && {pid: updatedNote.pid},
      title: updatedNote.title, 
      body: updatedNote.body,
      ...(updatedNote.lastModified || updatedNote.lastModified === '') && {lastModified: updatedNote.lastModified},
    };
    
    await updateDoc(noteDoc, updFields);
    // const updateTitle = await debounce((updatedNote) => {
    //   updateDoc(noteDoc, updFields);
    // }, 1500);
  }


  const createNote = async (newNote) => {
    // const newFirebaseNote = await addDoc(notesCollectionRef, {
    const newFirebaseNote = await setDoc(doc(db, "notes", newNote.id), {
      pid: newNote.pid,
      title: newNote.title,
      body: newNote.body,
      lastModified: newNote.lastModified,
    });

    // console.log('newFirebaseNote.id' + newFirebaseNote.id);
    // newNote.id = newFirebaseNote.id;
    setActiveNote(newNote.id);
    setNotes([newNote, ...notes]);
  }

  const deleteNote = async (id) => {
    const noteDoc = doc(db, "notes", id);
    await deleteDoc(noteDoc);
  };

  
  const onUpdateNote = (updatedNote) => {
    //-- Variable for the updated 'notes' array, using 'map()' function || parameters: 'notes' >> returns: 'updatedNote'
    const updatedNotesArr = notes.map((note) => {
      // If 'note.id' matches with 'updatedNote.id' returns 'updatedNote'
      if (note.id === updatedNote.id) {
        return updatedNote;
        // console.log("onUpdateNote" + updatedNote.title);
      }
      return note;
    });
  //-- 'useState' hook to set 'notes' updated array
    setNotes(updatedNotesArr);
    console.log(updatedNote);

    // let updatedNotePid = '';
    //   updatedNote.pid ? updatedNotePid = updatedNote.pid : '';
    // let updatedNoteNest = '';
      // updatedNote.nest && ;
    updateNote(updatedNote);
  }


  // const chkActiveNote = async (_id) => {
  //   // console.log('chkActiveNote: ' + _id);
  //   // chkActiveNotee(_id);
  // }

  const timerUpdate = () => {
    // console.log('chkCurrNote=' + currNoteId.current);
    // var _id = currNoteId.current;
    // (currNotes && _id) && chkActiveNotee(currNoteId.current);
  }

  const chkActiveNotee = async (_id) => {
    console.log('chkActiveNotee: ' + _id);
    const currNote = currNotes.current.find(({ id }) => id === _id);
    // console.log('currNote=' + currNote.title)
    //-- https://firebase.google.com/docs/firestore/query-data/get-data#get_a_document
    const docRef = doc(db, "notes", _id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log("Doc data:", docSnap.data());
      // console.log(docSnap.data().title + ' - ' + docSnap.data().lastModified + '(' + currNote.lastModified + ')');
      if (docSnap.data().lastModified > currNote.lastModified) {
        console.log('Note data not updated');
        console.log(docSnap.data());
        console.log(currNote);
        onUpdateNote({
          ...currNote,
          title: docSnap.data().title,
          body: docSnap.data().body,
          lastModified: docSnap.data().lastModified,
        });
      }
    } else {
      console.log('Note does not exist in the remote DB');
      window.alert('Note does not exist in the remote DB');
    }
  }


  //-- Function for adding a new object into 'notes' array using 'useState' hook || parameters: - >> returns: -
  const onAddNote = (hasPar) => {
    getActiveNote() ?
      console.log("id="+getActiveNote().id + " pid=" + hasPar) :
      console.log("id=null"+ " pid=" + hasPar) ;
    // return null
    //-- Variable for a 'newNote' object with 4 keys and values assigned
    const parId = getActiveNote() && hasPar
      ? getActiveNote().id 
      : null;
      console.log("parId=" + parId);
    // return null
    const newNote = {
      id: uuid(),
      pid: parId,
      nest: "",
      title: "Untitled Note",
      body: "<p><br></p>", //-- To avoid delete textview in prev. selected note
      lastModified: Date.now(),
    }
    // setActiveNote(newNote.id);
    // //-- 'useState' hook to set 'notes' array object adding 'newNote' by adding to spread operator
    // setNotes([newNote, ...notes]);
    console.log("newID=" + newNote.id);

    createNote(newNote);
  }

  const onDeleteNote = (noteId, noteTitle) => {
    // if (window.confirm(`Are you sure you want to delete: ${noteTitle}`)) {
      //-- 'useState' hook to set 'notes' array object by filtering array 'notes' for keys 'id' which are not equal to 'nodeId' (which remains filtered out)
      setNotes(notes.filter(({ id }) => id !== noteId));
      setActiveNote(null);
    // }
      deleteNote(noteId);
  }


  const getActiveNote = () => {
    // console.log("activeID="+activeNote);
    return notes.find(({ id }) => id === activeNote);
  }


//-- Return

  return (
    <div className="App">

      {/* <div className="w-100 text-center mt-2">
        <Link to="/profile" className="btn btn-primary w-100 mt-3">
          {currentUser.email}
        </Link>
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>

      <input
        placeholder="Title..."
        onChange={(event) => {
          setNewTitle(event.target.value);
        }}
      />
      <input
        placeholder="Body..."
        onChange={(event) => {
          setNewBody(event.target.value);
        }}
      />
      
      <button onClick={createNote}>New Note</button>

      {notes.map((note) => {
        return <div>
          <div id={note.id} contenteditable="true">
            <h1>Title: {note.title}</h1>
            <h3>{note.body}</h3>
          </div>
          <button
              onClick={() => {
                updateNote(note.id, note.body);
              }}
            >
              {" "}
              Update note
            </button>
            <button
              onClick={() => {
                deleteNote(note.id);
              }}
            >
              {" "}
              Delete note
            </button>
        </div>;
      })} */}

      <Sidebar 
        pageWrapId={'page-wrap'} 
        outerContainerId={'App'} 
        width={'24em'}
        multiRowsDisplay= {true}
        //-- props for NotesList
        notes={notes}
        onAddNote={onAddNote}
        onDeleteNote={onDeleteNote}
        onUpdateNote={onUpdateNote}
        activeNote={activeNote}
        setActiveNote={setActiveNote}
        currentUser={currentUser}
        handleLogout={handleLogout}
        // chkActiveNote={chkActiveNote}
        />
      <Main 
        activeNote={getActiveNote()}
        // activeNote={activeNote}
        onUpdateNote={onUpdateNote} 
        />
    </div>
  );
}




