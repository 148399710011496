import React from "react"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import Profile from "./authentication/Profile"
import Login from "./authentication/Login"
import Signup from "./authentication/Signup"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./authentication/ForgotPassword"
import UpdateProfile from "./authentication/UpdateProfile"
import "./App.css";
import Home from "./home/Home";

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Router>
          <AuthProvider>
            <Routes>
              
              <Route path="/" element={<PrivateRoute/>} >
                <Route path="/" element={<Navigate replace to="home" />}/>
                <Route path="/profile" element={<Profile/>} />
                <Route path="/update-profile" element={<UpdateProfile/>} />
                <Route path="/home" element={<Home/>} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>

              <Route path="/signup" element={<Signup/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </Container>
  )
}

export default App