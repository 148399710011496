import React, { useState } from "react";
import './Sidebar.css';
import { slide as Menu } from "react-burger-menu";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import NotesList from "../noteslist/NotesList"
import NotesTree from "../notestree/NotesTree"
import { TextEditorQuill_Scripts } from "../texteditor/TextEditorQuill_Scripts"


function Sidebar (props) {

  //-- To close sedebar when editor is clicked
  const { qlToolbarSwitch } = TextEditorQuill_Scripts();

  //-- Sidebar close link || https://stackoverflow.com/questions/64438927/how-to-close-react-burger-menu-when-link-click-in-a-component/67993191#67993191
    const [isOpen, setOpen] = useState(false)
    const handleIsOpen = () => {
      setOpen(!isOpen)
    }
    const closeSideBar = () => {
      setOpen(false)
      qlToolbarSwitch(false)
    }

  return (
    <Menu {...props}
      //-- Sidebar close link
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={closeSideBar}
    >

      <NotesTree
        notes={props.notes}
        onAddNote={props.onAddNote}
        onDeleteNote={props.onDeleteNote}
        onUpdateNote={props.onUpdateNote}
        activeNote={props.activeNote}
        setActiveNote={props.setActiveNote}
        chkActiveNote={props.chkActiveNote}
        closeSideBar={closeSideBar} //-- Sidebar close link
      />

      {/* <NotesList
        notes={props.notes}
        onAddNote={props.onAddNote}
        onDeleteNote={props.onDeleteNote}
        activeNote={props.activeNote}
        setActiveNote={props.setActiveNote}
        closeSideBar={closeSideBar} //-- Sidebar close link
      /> */}

      <div className="w-100 text-center mt-2">
        <Link to="/profile" className="btn btn-primary w-100 mt-3">
          {props.currentUser.email}
        </Link>
        <Button variant="link" onClick={props.handleLogout}>
          Log Out
        </Button>
      </div>

    </Menu>
  );
};

export default Sidebar;