import {isMobile} from 'react-device-detect';


export const TextEditorQuill_Scripts = () => {

        function test() {
            console.log('test complete');
            // return;
        }

    //-- Quill toolbar positioning mobile/desktop
        function qlToolbarPosition() {
            //-- Detect device || https://www.npmjs.com/package/react-device-detect
            var vpH = visualViewport.height;
            var mobileMode = true;
            if (!isMobile) {
            mobileMode = false;
            }
            // window.alert(checkOS());
            // console.log('isMobile='+isMobile)
        //-- Toolbar arrangement
            var qlToolbar_WhiteSpace = '',
            qlToolbar_Top = '56px',
            qlToolbar_Bottom = '',
            qlToolbar_Overflow = '',
            qlToolbar_Picker0_top = '',
            qlToolbar_Picker1_top = '',
            qlToolbar_Picker2_top = '';
            if (mobileMode) {
            qlToolbar_WhiteSpace = 'nowrap';
            qlToolbar_Top = '';
            qlToolbar_Bottom = '0px';
            qlToolbar_Overflow = 'auto';
            qlToolbar_Picker0_top = (vpH - 44 - 154);
            qlToolbar_Picker1_top = (vpH - 44 - 24);
            qlToolbar_Picker2_top = qlToolbar_Picker1_top;
            }
            var elements = document.querySelectorAll('.ql-toolbar');
            for (let element of elements) {
            element.style.whiteSpace = qlToolbar_WhiteSpace;
            element.style.top = qlToolbar_Top;
            element.style.bottom = qlToolbar_Bottom;
            element.style.overflow = qlToolbar_Overflow;
            }
        //-- Toolbar dropdowns
            if (document.getElementById('ql-picker-options-0')) { //-- To avoid break when select node after delete
                document.getElementById('ql-picker-options-0').style.setProperty('--vpH', `${qlToolbar_Picker0_top}px`);
                document.getElementById('ql-picker-options-1').style.setProperty('--vpH', `${qlToolbar_Picker1_top}px`);
                document.getElementById('ql-picker-options-2').style.setProperty('--vpH', `${qlToolbar_Picker2_top}px`);
            }
        //-- Editor container arrangement
            let h = document.querySelectorAll('.ql-toolbar')[0].clientHeight;
            // console.log(h);
            var qlContainer_Top = (56+h).toString() + 'px',
            qlContainer_Bottom = '',
            qlContainer_Height = 'calc(100% - ' + (56+h).toString() + 'px)';
            if (mobileMode) {
            qlContainer_Top = '';
            qlContainer_Bottom = '46px';
            qlContainer_Height = 'calc(100% - 100px)'
            }
            // console.log(qlContainer_Top);
            elements = document.querySelectorAll('.ql-container');
            for (let element of elements) {
            element.style.top = qlContainer_Top;
            element.style.bottom = qlContainer_Bottom;
            element.style.height = qlContainer_Height;
            }
            console.log('qlToolbarPosition complete')
        }

    //-- Quill toolbar and editor rezize when view change (iOS important)
        function qlViewResize() {
            window.visualViewport.addEventListener('resize', () => {
                // window.alert('resize');
                // var mainTitle = document.getElementById("title");
                var qlToolbar = document.querySelector('.ql-toolbar');
                var qlContainer = document.querySelector('.ql-container');
                var qlPicker0 = document.getElementById('ql-picker-options-0');
                var qlPicker1 = document.getElementById('ql-picker-options-1');
                var qlPicker2 = document.getElementById('ql-picker-options-2');
                var wwH = window.innerHeight,
                    vpH = visualViewport.height,
                    dH = Math.max(0, wwH - vpH - 1);
                if (isMobile && checkOS() === 'iOS') {
                // if (isMobile) {
                    setTimeout(() => {
                        qlToolbar.style.bottom = dH.toString() +'px';
                        qlContainer.style.bottom = 'calc(46px + ' + dH.toString() +'px)';
                        // qlContainer.style.top = '50px'; 
                        qlContainer.style.height = 'calc(' + vpH + 'px - 100px)';
                    }, 900);
                    qlPicker0.style.setProperty('--vpH', `${vpH - 44 - 154 + 152}px`);
                        qlPicker0.style.setProperty('height', `46px`);
                        qlPicker0.style.setProperty('white-space', 'nowrap');
                        qlPicker0.style.setProperty('overflow', 'auto');
                    qlPicker1.style.setProperty('--vpH', `${vpH - 44 - 24 + 22}px`);
                        qlPicker1.style.setProperty('height', `46px`);
                    qlPicker2.style.setProperty('--vpH', `${vpH - 44 - 24 + 22}px`);
                        qlPicker2.style.setProperty('height', `46px`);
                } else if (!isMobile)  { //-- Desktop browser
                    var h = qlToolbar.clientHeight;
                    qlContainer.style.top = (56 + h).toString() + 'px'
                    qlContainer.style.height = 'calc(100% - ' + (56+h).toString() + 'px)';
                } else {
                    //-- Just for test
                    // qlPicker0.style.setProperty('--vpH', `${vpH - 44 - 154 + 152}px`);
                    //     qlPicker0.style.setProperty('height', `46px`);
                    //     qlPicker0.style.setProperty('white-space', 'nowrap');
                    //     qlPicker0.style.setProperty('overflow', 'auto');
                    // qlPicker1.style.setProperty('--vpH', `${vpH - 44 - 24 + 22}px`);
                    //     qlPicker1.style.setProperty('height', `46px`);
                    // qlPicker2.style.setProperty('--vpH', `${vpH - 44 - 24 + 22}px`);
                    //     qlPicker2.style.setProperty('height', `46px`);
                }
            });
        }

    //-- Quill toolbar appear when editor selected
        function qlToolbarSwitch(state) {
            // console.log(state);
            let qlToolbar = document.querySelectorAll('.ql-toolbar')[0] || false;
            if (!state && isMobile && qlToolbar) {
            qlToolbar.style.visibility = 'hidden';
            let qlContainer = document.querySelectorAll('.ql-container')[0];
            qlContainer.style.bottom = '0';
            qlContainer.style.height = 'calc(100% - 54px)';
            } else if (state && isMobile && qlToolbar) {
            qlToolbar.style.visibility = 'visible';
            let qlContainer = document.querySelectorAll('.ql-container')[0];
            qlContainer.style.bottom = '46px';
            qlContainer.style.height = 'calc(100% - 100px)';
            }
            console.log('qlToolbarSwitch complete')
        }

    //-- Check OS utility
        function checkOS() {
            //-- Check for iOS || https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
            // window.alert('navigator.platform=' + navigator.platform + '; navigator.userAgent=' + navigator.userAgent)
            if ( [
              'iPad Simulator',
              'iPhone Simulator',
              'iPod Simulator',
              'iPad',
              'iPhone',
              'iPod'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            ) { return 'iOS'; }
            //-- Check for Win
            else if (
                navigator.platform.toLowerCase().indexOf("win32") > -1
                ) { return 'Win'; }
              //-- Check for Android
            else if ( 
                navigator.platform.toLowerCase().indexOf("linux") > -1
                && navigator.platform.toLowerCase().indexOf("arm") > -1
                && navigator.userAgent.toLowerCase().indexOf("android") > -1
                ) { return 'Android'; }
            return 'Unknown';
          }

        return { test, qlToolbarPosition, qlViewResize, qlToolbarSwitch }
}

    

