import React, { useEffect, useState } from "react"
// import DeleteIcon from '@mui/icons-material/Delete';
import { PlusCircle, NodePlus, Trash3, ArrowsMove } from 'react-bootstrap-icons';
import "./NotesTree.css";

// import { NotesTree_Scripts } from "./NotesTree_Scripts";

//-- Vomponent's function || props: 'notes' array, 'onAddNote' function, 'onDeleteNote' function, 'onActiveNote' function, 'setActiveNote' function >> returns: 'noteslist' (itself)
export default function NotesList ({
  notes,
  onAddNote,
  onDeleteNote,
  onUpdateNote,
  activeNote,
  setActiveNote,
//   chkActiveNote,
  closeSideBar,
}) {


// const { deleteNote } = NotesTree_Scripts();


//-- Instruction for sorting 'notes' array based on 'lastModified' key
    //   const sortedNotes =  notes.sort((a, b) => a.title.localeCompare(b.title));
    // const sortedNotes = notes.sort((a, b) => b.lastModified - a.lastModified);


 

//-- TreeView code || https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_treeview
    // var toggler = document.getElementsByClassName("caret");
    // var i;

    //-- Tree update
    useEffect(()=>{
        // setTimeout(() => {
            animateTree();
            activateTree();
        // }, 500);
    }, [notes])

    //-- Tree view animations
    function animateTree() {
        var toggler = document.getElementsByClassName("caret");
        for (var i = 0; i < toggler.length; i++) {
            // toggler[i].removeEventListener("click", toggleTreeItem);
            toggler[i].addEventListener("click", function toggleTreeItem() {
                this.parentElement.querySelector(".nested").classList.toggle("active");
                this.classList.toggle("caret-down");
                //-- Keep nesting configuration
                var p_id = this.parentElement.getElementsByClassName('treeItems')[0].id;
                var nestNote =  notes.filter(({id}) => id === p_id)[0];
                // console.log(nestNote.title + ' - ' + p_id);
                if (!notes.filter(({id}) => id === p_id)[0].nest
                || notes.filter(({id}) => id === p_id)[0].nest === ''
                ) {
                    onEditField(nestNote, 'nest', "active");
                } else {
                    onEditField(nestNote, 'nest', "");
                }
            });
        }
    }

    //-- Keep nesting configuration
    const onEditField = (nestNote, field, value) => {
        onUpdateNote({
          ...nestNote,
          [field]: value,
        });
        // console.log(field+"="+activeNote.id);
    }

    
    function activateTree() {
        var treeItems = document.getElementsByClassName("treeItems");
        console.log(treeItems.length);
        // console.log('activateTree()>activeNote=' + activeNote);
        // activeNote && console.log('outerHTML=' + document.getElementById(activeNote).outerHTML);
        activeNote && document.getElementById(activeNote).classList.add('active');
        for (var i = 0; i < treeItems.length; i++) {
            treeItems[i].addEventListener('click', function(e) {
                //-- Set selected note as active in the DB list
                clicked(this.id);
                e.stopPropagation();
            });
        }
        activeNote && activateNote(activeNote);
    }                 

    var clkCount = 0;
    function clicked(id) {
        console.log('active=' + activeNote + ' - ' + id)
        clkCount++;
        setActiveNote(id);
        // updActiveNote(id);
        //-- Highlight active note, blurring all others
        // var treeItems = document.getElementsByClassName("treeItems");
        // for (var j = 0; j < treeItems.length; j++) {
        //     document.getElementById(treeItems[j].id).classList.remove('active');
        // }
        // document.getElementById(id).classList.add('active')
        activateNote (id);
        //-- Single-click
        setTimeout(() => {
            clkCount = 0;
            // console.log('sgl id=' + id);
        }, 250);
        //-- Double-click
        if (clkCount === 2) {
            clkCount = 0;
            closeSideBar();
            // console.log('dbl id=' + id);
        }
    }

    function activateNote (id) {
        //-- Highlight active note, blurring all others
        var treeItems = document.getElementsByClassName("treeItems");
        for (var j = 0; j < treeItems.length; j++) {
            document.getElementById(treeItems[j].id).classList.remove('active');
        }
        document.getElementById(id).classList.add('active')
    }

    // function updActiveNote(id) {
    //     // console.log('updActiveNote=' + id);
    //     setActiveNote(id);
    //     chkActiveNote(id);
    // }
    
    // var moveSelected = '';
    const [idNoteToMove, setIdNoteToMove] = useState('');
    function moveNode() {
        console.log('moveNode:' + activeNote + ' idNoteToMove=' + idNoteToMove);
        var noteId = activeNote;
        // document.getElementById(noteId).classList.toggle('toMove');
        //-- Note to move flagged
        if (idNoteToMove === '' && noteId !== idNoteToMove) {
            // document.getElementById(noteId).style.color = '';
            document.getElementById(noteId).classList.add('toMove');
            document.getElementById('moveNode').style.color = 'red';
            setIdNoteToMove(noteId);
        //-- Note to move unflagged
        } else if ( idNoteToMove !== '' && noteId === idNoteToMove) {
            // document.getElementById(noteId).style.color = 'red';
            document.getElementById(noteId).classList.remove('toMove');
            document.getElementById('moveNode').style.color = '';
            setIdNoteToMove('');
        //-- Flagged note moved under selected note
        } else {
            let noteToMove = notes.filter(({ id }) => id === idNoteToMove)[0];
            onEditField(noteToMove, 'pid', activeNote);
            document.getElementById(idNoteToMove).classList.remove('toMove');
            document.getElementById('moveNode').style.color = '';
            setActiveNote(idNoteToMove);
            // setTimeout(() => {
            // var parNote =  notes.filter(({id}) => id === activeNote)[0];
            // if (!notes.filter(({id}) => id === activeNote)[0].nest
            //     || notes.filter(({id}) => id === activeNote)[0].nest === ''
            //     ) {
            // onEditField(parNote, 'nest', "active");
            // }
            // }, 1500);
            setIdNoteToMove('');
        }
        console.log('moveNodee:' + activeNote + ' idNoteToMovee=' + idNoteToMove);
    }

    function deleteNote () {
        if (activeNote) {
            var noteId = activeNote;
            var chiNotes = notes.filter(({pid}) => pid === noteId)
            var noteTitle = notes.filter(({id}) => id === noteId)[0].title;
            //-- Check children notes
            var noteChildren = " ?";
            if (chiNotes.length > 0) {
                noteChildren = '" and its children?'
            }
            //-- Confirm delete
            if (window.confirm('Are you sure you want to delete: "' + noteTitle + noteChildren )) {
                onDeleteNote(noteId, notes.find(x => x.id === noteId).title);
                if (chiNotes.length > 0) {
                    // deleteChildren(chiNotes);
                }
            }
        }
        else {
            window.alert('Select the note to delete');
        }
    }

    // function deleteChildren(chiNotes) {
    //     setTimeout(() => {
    //     for (var i=0; i<chiNotes.length; i++) {
    //         var chiId = chiNotes[i].id;
    //         setActiveNote(chiId);
    //         onDeleteNote(chiId, notes.find(x => x.id === chiId).title);
    //         var _chiNotes = notes.filter(({pid}) => pid === chiId)
    //         if (_chiNotes.length > 0) {
    //             deleteChildren(_chiNotes);
    //         }

    //     }
    //     }, 500);
    // }

    //-- Tree view creation
    function createTree() {
        const sortedNotes =  notes.sort((a, b) => a.title.localeCompare(b.title));
        //-- Notes 1st level parents / children
        var parNotes = sortedNotes.filter(({ pid }) => !pid );
        var chiNotes = sortedNotes.filter(({ pid }) => pid ); 
        //-- UL list initialized
        var list= '<ul id="notesTree">';
        //-- ca=caret, li=list item, ns=note title start, ne=note title end
        var li='<li>', le='</li>',
            ca='<span class="caret"></span>',
            ns='', ne='</span>';
        for (var i=0; i<parNotes.length ; i++) {
            ns = '<span id="' + parNotes[i].id + '" class="treeItems">';
            // console.log(parNotes[i].title + ' pid=' + parNotes[i].pid)
            if (//parNotes[i].pid && //-- No chidren
                chiNotes.filter(({pid}) => pid === parNotes[i].id).length === 0 
            ) {
                list = list + li + ns + parNotes[i].title + ne + le;
            }
            else { //-- With children
                var nestStatus = '', caretDir = '';
                if (parNotes[i].nest) {
                    nestStatus = parNotes[i].nest;
                    if (nestStatus === 'active') { caretDir = 'caret-down' }
                }
                ca = '<span class="caret ' + caretDir + '"></span>'
                list = list + li + ca + ns + parNotes[i].title + ne +
                    createBranches(parNotes[i].id, chiNotes)[0] + le;
            }
        }
        list = list + '</ul>';
        //-- Orphan notes
        ne='</div>'
        var orpList = "";
        for (i=0; i<chiNotes.length; i++) {
            if (notes.filter(({id}) => id === chiNotes[i].pid).length === 0) {
                ns = '<div id="' + chiNotes[i].id + '" class="treeItems">';
                orpList = orpList + ns + chiNotes[i].title + ne ;
            }
        }
        console.log('orpList: ' + orpList);
        if (orpList !== "") {
            list = list + '<hr>' + orpList;
        }
        // console.log('Tree notes: ' + notes.length);
        // console.log(list);
        return list;
    }

    function createBranches(p_id, chiNotes) { 
        var _chiNotes = notes.filter(({ pid }) => pid === p_id )
        var cL = _chiNotes.length;
        var nestStatus = '';
        if (notes.filter(({id}) => id === p_id)[0].nest) {
            nestStatus = notes.filter(({id}) => id === p_id)[0].nest;
        }
        var list = ''; // ' - ' + cL + ' - '+ p_id;
        list = list + '<ul class="nested ' + nestStatus +'">';
        var li='<li>', le='</li>',
        ca='<span class="caret"></span>',
        ns='', ne='</span>';
        for (var i=0; i<cL ; i++) {
            // ca = '<span class="caret ' + caretDir + ' "></span>';
            ns = '<span id="' + _chiNotes[i].id + '" class="treeItems">';
            if (//-- No chidren
                chiNotes.filter(({pid}) => pid === _chiNotes[i].id).length === 0 
            ) {
                list = list + li + ns + _chiNotes[i].title + ne + le;
            }
            else { //-- With children
                var nestStatus = '', caretDir = '';
                if (_chiNotes[i].nest) {
                    nestStatus = _chiNotes[i].nest;
                    if (nestStatus === 'active') { caretDir = 'caret-down' }
                }
                ca = '<span class="caret ' + caretDir + '"></span>'

                list = list + li + ca + ns + _chiNotes[i].title + ne +
                    createBranches(_chiNotes[i].id, chiNotes)[0] + le;
            }
        }
        list = list + '</ul>';
        // console.log('branches= ' + list);
        return [list];
    }

    //-- Return instruction: HTML of 'noteslist' component
    return (
      <div className="app-notestree">

        <div className="app-notestree-header">
          <h1>NotesTree</h1>
          <Trash3  onClick={(e) => deleteNote()} />
          <ArrowsMove id="moveNode" onClick={(e) => moveNode()} />
          <PlusCircle variant="primary" size={16} onClick={() => onAddNote(false)}/>
          <NodePlus variant="primary" size={24} onClick={() => onAddNote(true)}/>
        </div>

         {/* {React.createElement(createTree())} */}

        { React.createElement(React.Fragment, {},
            // JSX uses curly braces to interpret the string as JavaScript
            // rather than a literal string.
            // We also need to use the `dangerouslySetInnerHTML` property to
            // interpret the string as HTML rather than a string.
            // <div dangerouslySetInnerHTML={{ __html: createTree() }} />
            <div className="app-notestree-notes" dangerouslySetInnerHTML={{ __html:  createTree() }} />
        ) }

      </div>
    );
};

//   export default NotesList;
