// import ReactMarkdown from "react-markdown";
// import ReactQuill from 'react-quill';
import "./Main.css";
// import 'react-quill/dist/quill.snow.css';
import TextEditorQuill from '../texteditor/TextEditorQuill';
// import TextEditor from '../texteditor/TextEditor';



//-- Component's function || props: 'activeNote', onUpdateNote' >> returns: 'Main' (itself)
const Main = ({ activeNote, onUpdateNote }) => {

  //-- Instruction for returning default <div> element if there is no 'activeNote'
  if (!activeNote) return <div className="no-active-note">No Active Note</div>;

  //-- Function for updating the content of 'activeNote' object ()
  //-- Called by: HTML id="title" (input), id="body" (ReactQuill)
  const onEditField = (field, value) => {
    onUpdateNote({
      ...activeNote,
      [field]: value,
      lastModified: Date.now(),
    });
    // console.log(field+"="+activeNote.id);
  }

  // const updateTitle = debounce((field, value) => {
  //   onUpdateNote({
  //     ...activeNote,
  //     [field]: value,
  //     lastModified: Date.now(),
  //   });
  //   // console.log(field+"="+activeNote.id);
  // }, 1500);

  //-- Return instruction: HTML of 'Main' component
  return (
    <div className="app-main">
      <div className="app-main-note-edit">
        <input
          type="text"
          id="title"
          placeholder="Note Title..."
          value={activeNote.title}
          onChange={(e) => onEditField("title", e.target.value)}
          autoFocus
        />
        {/* <textarea
          id="body"
          placeholder="Write your note here..."
          value={activeNote.body}
          onChange={(e) => onEditField("body", e.target.value)}
        /> */}
        <TextEditorQuill 
          id="body"
          noteId={activeNote.id} 
          editorContent={activeNote.body} 
          onEditField={onEditField}
        />
        {/* <TextEditor
          id="bodyy"
          noteId={activeNote.id} 
          editorContent={activeNote.body} 
          onEditField={onEditField}
        /> */}
      </div>
      {/* <div className="app-main-note-preview">
        <h1 className="preview-title">{activeNote.title}</h1>
        <ReactMarkdown className="markdown-preview">
          {activeNote.body}
        </ReactMarkdown>
      </div> */}

    </div>
  );
};

export default Main;
