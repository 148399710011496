import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

//-- Wrapper for the current Route/Navigate, renaming component>>Component and the rest of the properties
export default function PrivateRoute({ ...props }) {

//-- Get currentUser from useAuth
const { currentUser } = useAuth()
// console.log(useAuth())

console.log('cu=' + currentUser)
return currentUser ? <Outlet /> : <Navigate to="/login" />

}